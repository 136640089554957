import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../assets/Veracious.png";
import favIcon from "../assets/Favorite Icon.svg";
import NewsLetter from "../assets/NewsLetter.svg";
import RedoIcon from "../assets/redo.svg";
import MapsHomeWorkRoundedIcon from "@mui/icons-material/MapsHomeWorkRounded";
import React from "react";
import ArticleIcon from "@mui/icons-material/Article";

const Header = styled(Box)(({ theme }) => ({
  padding: "1% 2%",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const HeaderComp = () => {
  const isSmall = useMediaQuery("(max-width:600px)");
  return (
    <Box>
      <Header>
        <Stack alignItems="center" direction="row" spacing={{ xs: 0, sm: 1 }}>
          <img height="40px" src={Logo} alt="" />
          <Typography
            sx={{
              fontFamily: "Righteous",
              backgroundImage:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Veracious
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction={!isSmall ? "row" : "column"}
          spacing={2}
          flexWrap="wrap"
          justifyContent={{ xs: "center", sm: "normal" }}
          gap={{ xs: 2, sm: 0 }}
          // mr={state ? 0 : 10.3}
        >
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "14px",
              backgroundImage:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Ali@Veracious.ai
          </Typography>

          <Stack
            direction="row"
            flexWrap={"wrap"}
            justifyContent={"center"}
            spacing={1}
            gap={{
              xs: 2,
              sm: 0,
            }}
          >
            <Link to={"/"}>
              <Button
                variant="contained"
                sx={{
                  background: "#FFFFFF0D !important",
                  textTransform: "none",
                  height: "32px",
                }}
                // onClick={toggle}
              >
                {/* {state ? (
              <> */}
                {/* <img style={{ marginRight: "10px" }} src={favIcon} alt="" />
                 */}
                <MapsHomeWorkRoundedIcon
                  sx={{ marginRight: "10px", color: "#ABADB6", width: "20px" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "13px",
                    backgroundImage:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Home
                </Typography>
                {/* </>
            ) : (
              <img height="20px" src={RedoIcon} alt="" />
            )} */}
              </Button>
            </Link>

            <Link
              to="https://blog.veracious.ai/"
              // target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "#FFFFFF0D !important",
                  textTransform: "none",
                }}
              >
                <ArticleIcon
                  sx={{
                    marginRight: "10px",
                    color: "#9FA2AC",
                    fontSize: "20px",
                  }}
                />{" "}
                {/* Using MUI icon instead of image */}
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "13px",
                    backgroundImage:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Blog
                </Typography>
              </Button>
            </Link>

            <Link to={"/products"}>
              <Button
                variant="contained"
                sx={{
                  background: "#FFFFFF0D !important",
                  textTransform: "none",
                }}
                // onClick={toggle}
              >
                {/* {state ? (
              <> */}
                <img style={{ marginRight: "10px" }} src={favIcon} alt="" />

                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "13px",
                    backgroundImage:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Our Products
                </Typography>
                {/* </>
            ) : (
              <img height="20px" src={RedoIcon} alt="" />
            )} */}
              </Button>
            </Link>

            <Link to="/news-letter" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  background: "#FFFFFF0D !important",
                  textTransform: "none",
                }}
              >
                <img style={{ marginRight: "10px" }} src={NewsLetter} alt="" />

                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    fontSize: "13px",
                    backgroundImage:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Newsletter
                </Typography>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Header>
      {/* {state ? <Landing /> : <Tools />} */}
    </Box>
  );
};

export default HeaderComp;

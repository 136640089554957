import React from "react";
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import TalkitoutLogo from "../assets/talkitout logo.png";
import ExpIcon from "../assets/explore icon.svg";
import ResearchIcon from "../assets/Research icon.png";
import styled from "@emotion/styled";
import Separator from "../assets/Separator.svg";

const RootStyle = styled(Box)(({ theme }) => ({
  background: "#121831",
  // minHeight: "89vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
export const ToolCard = styled(Box)({
  maxWidth: "350px",
  background:
    "linear-gradient(142.21deg, rgba(27, 51, 81, 0.3) 19.63%, rgba(67, 144, 238, 0.3) 83.01%)",
  backgroundBlendMode: "overlay",
  // borderRadius: "20px",
  border: "0.6px solid",
  boxShadow: "0px 23.9px 23.9px 0px #00000040",
  borderImageSource:
    "linear-gradient(178.6deg, #FFFFFF 13.55%, rgba(0, 0, 0, 0.15) 83.77%)",
    cursor:"pointer"
});

const BtnExplore = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "10px",
  color: "var(--sengrey, #C2C2C2)",
  background:
    "linear-gradient(180deg, rgba(49, 58, 91, 0.00) 0%, #313A5B 78.91%, rgba(49, 58, 91, 0.22) 100%) !important",
  border: "1px solid",
  borderColor: "linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, 1)",
}));

const Title = styled(Typography)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(to right, #387BC8 0%, #387BC8 50%, #38C8AE 50%, #38C8AE 100%)",
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "24px",
}));

function Tools() {
  const isLarge = useMediaQuery("(min-width:1200px)");
  const moreLarge = useMediaQuery("(min-width:1300px)");
  return (
    <RootStyle sx={{ px:3, py: moreLarge ? 3:0 }}>
      <Container sx={{ mt: {
              xs: 4,
              sm: 4,
            },}}>
        <Grid container spacing={5} justifyContent="center">
          {/* <Grid item md={5} sm={6} xs={12} align="center">
            <ToolCard>
              <Stack
                p={6}
                alignItems="center"
                justifyContent="center"
                spacing={3}
              >
                <img height="204px" src={TalkitoutLogo} alt="" />
                <Title>Talk it out</Title>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Raleway",
                    fontWeight: 400,
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Knowledge Discovery the way it should be : web, docs, other
                  sources
                </Typography>
                <a href="https://www.talkitout.ai">
                  <BtnExplore variant="contained">
                    <img style={{ marginRight: "5px" }} src={ExpIcon} alt="" />
                    Explore Now
                  </BtnExplore>
                </a>
              </Stack>
            </ToolCard>
          </Grid> */}
          <Grid item md={5} sm={6} xs={12} align="center">
            <ToolCard sx={{borderRadius:"20px"}}>
              <Stack
                p={3}
                alignItems="center"
                justifyContent="center"
                spacing={isLarge? 3.5 : 3}
              >
                <img src={ResearchIcon} alt="" style={{height: isLarge? "180px":"148px"}} />
                <Typography
                  sx={{
                    backgroundImage:
                      "linear-gradient(180deg, #3C87FF 0%, #3CDBFF 100%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontFamily: "Righteous",
                    fontWeight: 400,
                    fontSize: "24px",
                  }}
                >
                  ResearchPal
                </Typography>

                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Raleway",
                    fontWeight: 400,
                    fontSize: "15px",
                    textAlign: "center",
                    pb: {xs:1,sm:3, lg:5},
                  }}
                >
                  For the Researchers, By the Researchers
                </Typography>
                <a href="https://researchpal.co">
                  <BtnExplore variant="contained">
                    <img style={{ marginRight: "5px" }} src={ExpIcon} alt="" />
                    Explore Now
                  </BtnExplore>
                </a>
              </Stack>
            </ToolCard>
          </Grid>
        </Grid>
        
        <Box sx={{ display: "flex", justifyContent: "center", mt:{xs:2.5,sm:4} }}>
            <img width="100%" src={Separator} alt="" />
          </Box>
      </Container>
    </RootStyle>
  );
}

export default Tools;
